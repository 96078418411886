import { Text } from '@/remotion/helpers/Text';
import { AbsoluteFill } from 'remotion';

interface Props {
  title: string;
}

export const SurgyTitle = (props: Props) => {
  return (
    <AbsoluteFill className="bg-white items-center justify-center">
      <Text className="text-5xl" from={10} to={50}>
        {props.title}
      </Text>
    </AbsoluteFill>
  );
};
