import React from 'react';
import { Text } from '@/remotion/helpers/Text';
import { Wraper } from '@/remotion/helpers/Wraper';
import { z } from 'zod';
import { sampleSchema } from './SampleSchema';

const SampleComposition: React.FC<z.infer<typeof sampleSchema>> = ({ text }) => {
  return (
    <Wraper className="bg-white">
      <div className="w-full h-full flex justify-center items-center">
        <Text className="text-primary text-5xl" from={0} to={30}>
          {text}
        </Text>
      </div>
    </Wraper>
  );
};

export default SampleComposition;
