import { useMemo } from 'react';
import { Series } from 'remotion';
import { z } from 'zod';
import birthdayMusic from '@/remotion/assets/sounds/birthday.mp3';
import birthdayVideo from '@/remotion/assets/videos/vintage-orange.mp4';
import { Wraper } from '@/remotion/helpers/Wraper';
import { birthdayInvitationSchema } from '@/shared/schemas/BirthdaySchema.ts';
import { getTemplateVideo, templateSkus } from '@/shared/services/TemplatesService.ts';
import { BirthdayDetails, BirthdayInviters, BirthdayPhoto, BirthdayTitle, BirthdayWelcome } from './BirthdayComponents';

const BirthdayInvitation: React.FC<z.infer<typeof birthdayInvitationSchema>> = props => {
  const { language, color = '#ffffff' } = props;
  const template = useMemo(() => getTemplateVideo('birthday', templateSkus.birthdayVintageOrangeInvitation), []);
  const labels = template?.languages[language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={birthdayVideo} playbackRate={0.9} music={birthdayMusic}>
      <Series>
        <Series.Sequence durationInFrames={50}>
          <BirthdayWelcome props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={50}>
          <BirthdayTitle props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={80}>
          <BirthdayPhoto props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={60}>
          <BirthdayDetails props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={50}>
          <BirthdayInviters props={props} labels={labels} color={color} />
        </Series.Sequence>
      </Series>
    </Wraper>
  );
};

export default BirthdayInvitation;
