import { z } from 'zod';
import { LANGUAGES, VIDEO_TYPES } from '@/ui/shared/config/constants';
import { FORM_TYPES } from '@/ui/shared/types/types';

export const technologyTags = [
  { sku: 'logo-animation', text: 'Logo Animation' },
  { sku: 'animated-logo', text: 'Animated Logo' },
  { sku: 'custom-logo-animation', text: 'Custom Logo Animation' },
  { sku: 'logo-design-animation', text: 'Logo Design Animation' },
  { sku: 'logo-animation-services', text: 'Logo Animation Services' },
  { sku: 'logo-animation-studio', text: 'Logo Animation Studio' },
  { sku: 'motion-graphics-logo', text: 'Motion Graphics Logo' },
  { sku: 'professional-logo-animation', text: 'Professional Logo Animation' },
  { sku: 'logo-animation-for-youtube', text: 'Logo Animation For YouTube' },
  { sku: 'youtube-logo-animation', text: 'YouTube Logo Animation' },
  { sku: 'youtube-intro-logo', text: 'YouTube Intro Logo' },
  { sku: 'animated-logo-for-youtube', text: 'Animated Logo For YouTube' },
  { sku: 'youtube-logo-intro', text: 'YouTube Logo Intro' },
  { sku: 'youtube-branding-animation', text: 'YouTube Branding Animation' },
  { sku: 'logo-animation-video', text: 'Logo Animation Video' },
  { sku: 'advertisement-creation', text: 'Advertisement Creation' },
  { sku: 'ad-design', text: 'Ad Design' },
  { sku: 'ad-creation-services', text: 'Ad Creation Services' },
  { sku: 'digital-ads', text: 'Digital Ads' },
  { sku: 'video-advertisements', text: 'Video Advertisements' },
  { sku: 'creative-ad-agency', text: 'Creative Ad Agency' },
  { sku: 'online-advertisement-creation', text: 'Online Advertisement Creation' },
  { sku: 'social-media-ads', text: 'Social Media Ads' },
  { sku: 'tv-commercial-production', text: 'TV Commercial Production' },
  { sku: 'advertising-campaigns', text: 'Advertising Campaigns' }
];

export const logoAnimationSchema = z.object({
  type: z.string(),
  language: z.nativeEnum(LANGUAGES),
  color: z.string().optional(),
  bgColor: z.string().optional(),
  animation: z.string(),
  name: z.string(),
  message: z.string(),
  image: z.string()
});

export const logoAnimationForm = [
  { type: FORM_TYPES.PNG, key: 'image', label: 'Select logo' },
  { type: FORM_TYPES.COLOR, key: 'color', label: 'Choose text color' },
  { type: FORM_TYPES.COLOR, key: 'bgColor', label: 'Choose background color' },
  { type: FORM_TYPES.SELECT, key: 'animation', label: 'Select Animation', options: ['Spring', 'Rotation', 'FadeIn', 'HeartBeat'] },
  { type: FORM_TYPES.TEXT, key: 'name', label: 'Enter name' },
  { type: FORM_TYPES.TEXTAREA, key: 'message', label: 'Enter message' }
];

export const logoAnimationLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Logo Animation',
      description: 'Logo Animation',
      color: '#005ee6',
      bgColor: '#ffffff',
      animation: 'Spring',
      name: 'Inyter',
      message: 'Video invitations and wishes',
      image: ''
    }
  }
};

export const socialMediaCountAnimationSchema = z.object({
  type: z.string(),
  language: z.nativeEnum(LANGUAGES),
  color: z.string().optional(),
  count: z.string(),
  message: z.string(),
  image: z.string()
});

export const socialMediaCountAnimationForm = [
  { type: FORM_TYPES.FILE, key: 'image', label: 'Select image' },
  { type: FORM_TYPES.COLOR, key: 'color', label: 'Choose text color' },
  { type: FORM_TYPES.NUMBER, key: 'count', label: 'Enter count' },
  { type: FORM_TYPES.TEXTAREA, key: 'message', label: 'Enter message' }
];

export const socialMediaCountAnimationLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Social Media Count Animation',
      description: 'Create social media count animation to show celebration to your friends and family.',
      color: '#005ee6',
      count: '100000',
      message:
        'Reaching this follower milestone is truly special, and it’s all thanks to you – our amazing community! 💖 Your support motivates us every day, and we’re so grateful to have you with us on this journey.',
      image: ''
    }
  }
};
