import { useMemo } from 'react';
import { z } from 'zod';
import birthdayMusic from '@/remotion/assets/sounds/birthday.mp3';
import birthdayVideo from '@/remotion/assets/videos/celebration-baloons.mp4';
import WishesPhotoContent from '@/remotion/helpers/WishesPhotoContent';
import { Wraper } from '@/remotion/helpers/Wraper';
import { birthdaySchema } from '@/shared/schemas/BirthdaySchema.ts';
import { getTemplateVideo, templateSkus } from '@/shared/services/TemplatesService.ts';

const BirthdayWishesWithBalloons: React.FC<z.infer<typeof birthdaySchema>> = props => {
  const { language, color = '#ad3844', message, image } = props;
  const template = useMemo(() => getTemplateVideo('birthday', templateSkus.birthdayBalloonsWishes), []);
  const labels = template?.languages[language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={birthdayVideo} playbackRate={0.5} music={birthdayMusic}>
      <WishesPhotoContent
        className="w-[850px] flex flex-col justify-center items-center pl-8"
        labels={labels}
        language={language}
        message={message}
        image={image}
        color={color}
      />
    </Wraper>
  );
};

export default BirthdayWishesWithBalloons;
