import { Series } from 'remotion';
import { Text } from './Text';

interface Props {
  className: string;
  color: string;
  message: string;
  language: string;
  labels: any;
}

const WishesContent = (props: Props) => {
  const { message, className, color } = props;
  return (
    <Series>
      <Series.Sequence durationInFrames={290}>
        <div className={className}>
          <Text className="text-[32px] mb-5 text-center leading-relaxed line-clamp-5" color={color} from={20} to={50}>
            {message}
          </Text>
        </div>
      </Series.Sequence>
    </Series>
  );
};

export default WishesContent;
