import * as _ from 'lodash';
import { Composition } from 'remotion';
import '../src/index.css';
import { compositionMapping } from '../src/services/TemplatesMappingService';
import { getTemplateMetaData, schemaMapping, templates } from '../src/shared/services/TemplatesService';
import SampleComposition from './compositions/sample/SampleComposition';
import { sampleSchema } from './compositions/sample/SampleSchema';
import { SurgyComposition } from './compositions/surgy/SurgyComposition';

export const RemotionRoot = () => {
  const flattedTemplates = _.uniqBy(
    _.flatMap(templates.list, item => item.videos),
    'sku'
  );
  return (
    <>
      {flattedTemplates.map(video => {
        const platform =
          !!video?.platforms?.length &&
          getTemplateMetaData(
            video.duration,
            video.platforms.some(platform => platform === 'web' || platform === 'square'),
            video.platforms.some(platform => platform === 'mobile'),
            video.platforms.some(platform => platform === 'square')
          );
        const defaultPlatform = video?.platforms[0];

        video.sku.includes('product') && console.log(video);

        return (
          <Composition
            fps={24}
            id={video.sku}
            key={video.sku}
            component={compositionMapping[video.sku + '-' + defaultPlatform]}
            durationInFrames={platform[defaultPlatform]?.compositionDuration || video.duration}
            width={platform[defaultPlatform]?.compositionWidth || 1200}
            height={platform[defaultPlatform]?.compositionHeight || 720}
            schema={schemaMapping[video.schema]}
            defaultProps={templates.languages[video.languages].english.props}
          />
        );
      })}
      <Composition
        id="sample-video"
        component={SampleComposition}
        durationInFrames={60}
        fps={24}
        width={1280}
        height={720}
        schema={sampleSchema as any}
        defaultProps={{ text: 'Sample' }}
      />
      <Composition id="surgy-trailer" component={SurgyComposition} durationInFrames={6 * 60} fps={30} width={1280} height={720} />
    </>
  );
};
