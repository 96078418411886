import { Series } from 'remotion';
import { SurgyIntroduction } from './SurgyIntroduction';
import { SurgyTitle } from './SurgyTitle';

export const SurgyComposition = () => {
  return (
    <Series>
      <Series.Sequence durationInFrames={200}>
        <SurgyIntroduction />
      </Series.Sequence>
      <Series.Sequence durationInFrames={60}>
        <SurgyTitle title="Why Surgy?" />
      </Series.Sequence>
      {/*<Series.Sequence durationInFrames={100}>*/}
      {/*  <SurgyFeatures*/}
      {/*    animation={rocketAnimation}*/}
      {/*    title="Work Better Together"*/}
      {/*    subTitle="Conectr will improve your business productivity by automating all of your day to day activities"*/}
      {/*  />*/}
      {/*</Series.Sequence>*/}
      {/*<Series.Sequence durationInFrames={100}>*/}
      {/*  <SurgyFeatures*/}
      {/*    animation={rocketAnimation}*/}
      {/*    title="Work Better Together"*/}
      {/*    subTitle="Conectr will improve your business productivity by automating all of your day to day activities"*/}
      {/*  />*/}
      {/*</Series.Sequence>*/}
    </Series>
  );
};
