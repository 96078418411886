import logo from '@/remotion/assets/images/surgy.png';
import femaleAudio from '@/remotion/assets/sounds/surgy-female-voice.mp3';
import { Image } from '@/remotion/helpers/Image';
import { Text } from '@/remotion/helpers/Text';
import { AbsoluteFill, Audio, Sequence, useCurrentFrame } from 'remotion';
import { rotateEffect } from '../../services/utils';

export const SurgyIntroduction = () => {
  const frame = useCurrentFrame();
  const rotate = rotateEffect(frame, 0, 200, 360, 0);

  return (
    <AbsoluteFill className="bg-white items-center justify-center">
      <Sequence from={10}>
        <Audio src={femaleAudio} volume={0.5} />
      </Sequence>
      <div className="mb-2" style={{ transform: `rotate(${rotate}deg)` }}>
        <Image source={logo} width={200} height={200} from={0} to={30} />
      </div>
      <div className="mb-1">
        <Text className="text-5xl" from={20} to={60}>
          Welcome to Surgy!
        </Text>
      </div>
      <div className="mb-1">
        <Text className="text-4xl text-gray-500" from={80} to={130}>
          Private Social Network & Collaboration Tools for Business
        </Text>
      </div>
      <div className="mb-1">
        <Text className="text-3xl text-gray-500" from={130} to={150}>
          www.surgy.app
        </Text>
      </div>
    </AbsoluteFill>
  );
};
