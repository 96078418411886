import { useMemo } from 'react';
import { Globe, MapPin, PhoneCall } from 'lucide-react';
import { spring, useCurrentFrame, useVideoConfig } from 'remotion';
import { z } from 'zod';
import imgFood from '@/remotion/assets/images/food.png';
import birthdayMusic from '@/remotion/assets/sounds/birthday.mp3';
import { Image } from '@/remotion/helpers/Image.tsx';
import { Text } from '@/remotion/helpers/Text';
import { Wraper } from '@/remotion/helpers/Wraper';
import { adsAnimationSchema } from '@/shared/schemas/AdvertisementsSchema.ts';
import { getTemplateVideo, templateSkus } from '@/shared/services/TemplatesService.ts';

const Advertisement: React.FC<z.infer<typeof adsAnimationSchema>> = props => {
  const { color = '#fff', bgColor = '#0069ff', name, message, phone, website, location, image, buttonText } = props;
  const template = useMemo(() => getTemplateVideo('advertisements', templateSkus.productAdvertisement), []);
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const totalFrames = 5 * 24;
  const imageScale = spring({
    frame: frame - totalFrames * 0.1,
    fps,
    from: 0.5,
    to: 1,
    config: { mass: 1, damping: 10, stiffness: 50 },
    durationInFrames: totalFrames
  });

  return (
    <Wraper color={color} template={template} data={props} music={birthdayMusic}>
      <div className="w-full max-w-[600px] h-full flex flex-col justify-center items-center m-auto">
        <div
          className={`absolute inset-0 -z-10`}
          style={{
            backgroundImage: `radial-gradient(ellipse at center, ${bgColor}, ${bgColor}70)`
          }}></div>
        <Text className="text-primary text-3xl mb-5 line-clamp-1 font-bold" from={0} to={20} color={color}>
          {name}
        </Text>
        <Text className="text-primary text-base line-clamp-3 text-center" from={10} to={30} color={color}>
          {message}
        </Text>
        <div className="py-5">
          <Image
            from={20}
            to={40}
            source={image || imgFood}
            className="w-[350px] h-[350px] rounded-lg"
            style={{ transform: `scale(${imageScale})` }}
          />
        </div>
        <Text className="px-5 py-2.5 rounded-full text-lg font-bold" style={{ backgroundColor: color }} from={30} to={50} color={bgColor}>
          {buttonText}
        </Text>
        <div className="h-[50px] w-full"></div>
        <div className="w-full max-w-[650px] m-auto flex flex-row justify-between items-center absolute bottom-0 mb-5">
          {!!phone && (
            <div className="flex flex-row items-center">
              <Text className="mr-3" from={40} to={60}>
                <PhoneCall className="w-6 h-6" color={color} />
              </Text>
              <div>
                <Text className="text-base" from={40} to={60} color={color}>
                  Call to find out more
                </Text>
                <Text className="text-base font-bold -mt-0.5" from={40} to={60} color={color}>
                  {phone}
                </Text>
              </div>
            </div>
          )}
          {!!website && (
            <div className="flex flex-row items-center">
              <Text className="mr-3" from={40} to={60}>
                <Globe className="w-6 h-6" color={color} />
              </Text>
              <div>
                <Text className="text-base" from={40} to={60} color={color}>
                  Visit to find out more
                </Text>
                <Text className="text-base font-bold -mt-0.5" from={40} to={60} color={color}>
                  {website}
                </Text>
              </div>
            </div>
          )}
          {!!location && (
            <div className="flex flex-row items-center">
              <Text className="mr-3" from={40} to={60}>
                <MapPin className="w-6 h-6" color={color} />
              </Text>
              <div>
                <Text className="text-base" from={40} to={60} color={color}>
                  Visit our location
                </Text>
                <Text className="text-base font-bold -mt-0.5" from={40} to={60} color={color}>
                  {location}
                </Text>
              </div>
            </div>
          )}
        </div>
      </div>
    </Wraper>
  );
};

export default Advertisement;
