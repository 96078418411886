import { useMemo } from 'react';
import { Globe, MapPin, PhoneCall } from 'lucide-react';
import { spring, useCurrentFrame, useVideoConfig } from 'remotion';
import { z } from 'zod';
import imgFood from '@/remotion/assets/images/food.png';
import birthdayMusic from '@/remotion/assets/sounds/birthday.mp3';
import { Image } from '@/remotion/helpers/Image.tsx';
import { Text } from '@/remotion/helpers/Text';
import { Wraper } from '@/remotion/helpers/Wraper';
import { adsAnimationSchema } from '@/shared/schemas/AdvertisementsSchema.ts';
import { getTemplateVideo, templateSkus } from '@/shared/services/TemplatesService.ts';

const Advertisement: React.FC<z.infer<typeof adsAnimationSchema>> = props => {
  const { color = '#fff', bgColor = '#5145CD', name, message, phone, website, location, image, buttonText } = props;
  const template = useMemo(() => getTemplateVideo('advertisements', templateSkus.productAdvertisement4), []);
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const totalFrames = 5 * 24;
  const imageScale = spring({
    frame: frame - totalFrames * 0.1,
    fps,
    from: 0.5,
    to: 1,
    config: { mass: 1, damping: 10, stiffness: 50 },
    durationInFrames: totalFrames
  });

  return (
    <Wraper color={color} template={template} data={props} music={birthdayMusic}>
      <div className="w-full max-w-[1000px] h-full flex flex-row justify-center items-center m-auto">
        <div
          className={`absolute inset-0 -z-10`}
          style={{
            backgroundImage: `radial-gradient(ellipse at center, ${bgColor}, ${bgColor}70)`
          }}></div>
        <div className="w-[550px]">
          <Text className="text-4xl mb-5 line-clamp-1 font-bold" from={0} to={20} color={color}>
            {name}
          </Text>
          <Text className="!text-2xl line-clamp-5 mb-10" from={10} to={30} color={color}>
            {message}
          </Text>
          <Text className="px-5 py-3 rounded-full text-2xl font-bold inline" style={{ backgroundColor: color }} from={20} to={40} color={bgColor}>
            {buttonText}
          </Text>
        </div>
        <div className="w-[450px] flex justify-end items-center">
          <Image
            from={30}
            to={50}
            source={image || imgFood}
            className="w-[400px] h-[400px] rounded-lg"
            style={{ transform: `scale(${imageScale})` }}
          />
        </div>
        <div className="w-full max-w-[1000px] m-auto flex flex-row justify-between items-center absolute bottom-0 pb-5">
          {!!phone && (
            <Text className="text-base font-bold" from={40} to={60} color={color}>
              <PhoneCall className="w-5 h-5 mr-2 inline" color={color} />
              {phone}
            </Text>
          )}
          {!!website && (
            <Text className="text-base font-bold" from={40} to={60} color={color}>
              <Globe className="w-5 h-5 mr-2 inline" color={color} />
              {website}
            </Text>
          )}
          {!!location && (
            <Text className="text-base font-bold" from={40} to={60} color={color}>
              <MapPin className="w-5 h-5 mr-2 inline" color={color} />
              {location}
            </Text>
          )}
        </div>
      </div>
    </Wraper>
  );
};

export default Advertisement;
