import { useMemo } from 'react';
import { z } from 'zod';
import defaultImage from '@/remotion/assets/images/anniversary-person.jpg';
import birthdayMusic from '@/remotion/assets/sounds/birthday.mp3';
import birthdayVideo from '@/remotion/assets/videos/falling-springs.mp4';
import WishesPhotoContent from '@/remotion/helpers/WishesPhotoContent';
import { Wraper } from '@/remotion/helpers/Wraper';
import { lifeEventsSchema } from '@/shared/schemas/LifeEventsSchema.ts';
import { getTemplateVideo, templateSkus } from '@/shared/services/TemplatesService.ts';

const AnniversaryWishesWithSprings: React.FC<z.infer<typeof lifeEventsSchema>> = props => {
  const { language, color = '#000000', message, image } = props;
  const template = useMemo(() => getTemplateVideo('life-events', templateSkus.anniversarySpringsWishes), []);
  const labels = template?.languages[language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={birthdayVideo} playbackRate={0.5} music={birthdayMusic}>
      <WishesPhotoContent
        className="w-[850px] m-auto flex flex-col justify-center items-center pl-8"
        labels={labels}
        language={language}
        message={message}
        image={image}
        defaultImage={defaultImage}
        color={color}
      />
    </Wraper>
  );
};

export default AnniversaryWishesWithSprings;
