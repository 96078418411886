import { useMemo } from 'react';
import { Series } from 'remotion';
import logo from '@/remotion/assets/images/logo.png';
import birthdayMusic from '@/remotion/assets/sounds/birthday.mp3';
import socialVideo from '@/remotion/assets/videos/social-media.mp4';
import { Center } from '@/remotion/helpers/Center.tsx';
import { Image } from '@/remotion/helpers/Image';
import { Text } from '@/remotion/helpers/Text';
import { Wraper } from '@/remotion/helpers/Wraper.tsx';
import { getTemplateVideo, templateSkus } from '@/shared/services/TemplatesService.ts';
import AnimatedNumber from '../../helpers/AnimateNumber';

const SocialMediaCountAnimation = (props: any) => {
  const { color = '#000000', count, message, image } = props;
  const template = useMemo(() => getTemplateVideo('technology', templateSkus.socialMediaCountAnimation), []);

  return (
    <Wraper color={color} template={template} data={props} video={socialVideo} playbackRate={1} music={birthdayMusic}>
      <Series>
        <Series.Sequence durationInFrames={120}>
          <Center>
            <Image
              source={image || logo}
              className="w-[130px] h-[130px] mb-2 border-[5px] rounded-full"
              from={0}
              to={20}
              style={{ borderColor: color }}
            />
            <AnimatedNumber color={color} className="text-[60px]" to={20} end={parseInt(count) || 0} />
            {!!message && (
              <Text className="text-[30px] line-clamp-5 max-w-[800px] text-center leading-relaxed" from={0} to={20} color={color}>
                {message}
              </Text>
            )}
          </Center>
        </Series.Sequence>
      </Series>
    </Wraper>
  );
};

export default SocialMediaCountAnimation;
