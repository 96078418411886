import { useMemo } from 'react';
import { Series } from 'remotion';
import { z } from 'zod';
import birthdayMusic from '@/remotion/assets/sounds/birthday.mp3';
import birthdayVideo from '@/remotion/assets/videos/abstract-with-double-rotation.mp4';
import { Wraper } from '@/remotion/helpers/Wraper';
import { houseWarmingInvitationSchema } from '@/shared/schemas/HouseWarmingSchema.ts';
import { getTemplateVideo, templateSkus } from '@/shared/services/TemplatesService.ts';
import { HouseWarmingDetails, HouseWarmingInviters, HouseWarmingPhoto, HouseWarmingTitle, HouseWarmingWelcome } from './HouseWarmingComponents';

const HouseWarmingInvitation: React.FC<z.infer<typeof houseWarmingInvitationSchema>> = props => {
  const { language, color = '#eccd8d' } = props;
  const template = useMemo(() => getTemplateVideo('house-warming', templateSkus.houseWarmingAbstractDoubleRotationInvitation), []);
  const labels = template?.languages[language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={birthdayVideo} playbackRate={0.9} music={birthdayMusic}>
      <Series>
        <Series.Sequence durationInFrames={50}>
          <HouseWarmingWelcome props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={50}>
          <HouseWarmingTitle props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={80}>
          <HouseWarmingPhoto props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={60}>
          <HouseWarmingDetails props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={50}>
          <HouseWarmingInviters props={props} labels={labels} color={color} />
        </Series.Sequence>
      </Series>
    </Wraper>
  );
};

export default HouseWarmingInvitation;
