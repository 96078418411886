import { useMemo } from 'react';
import { z } from 'zod';
import festivalMusic from '@/remotion/assets/sounds/snowman.mp3';
import festivalVideo from '@/remotion/assets/videos/festival-snowman.mp4';
import WishesContent from '@/remotion/helpers/WishesContent';
import { Wraper } from '@/remotion/helpers/Wraper';
import { festivalSchema } from '@/shared/schemas/FestivalsSchema.ts';
import { getTemplateVideo, templateSkus } from '@/shared/services/TemplatesService.ts';

const FestivalWishesForSnowman: React.FC<z.infer<typeof festivalSchema>> = props => {
  const { language, color = '#e43d31', message } = props;
  const template = useMemo(() => getTemplateVideo('festivals', templateSkus.festivalSnowmanWishes), []);
  const labels = template?.languages[language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={festivalVideo} playbackRate={0.5} music={festivalMusic}>
      <WishesContent
        className="w-[850px] flex flex-col justify-end ml-[350px] pb-16"
        labels={labels}
        language={language}
        message={message}
        color={color}
      />
    </Wraper>
  );
};

export default FestivalWishesForSnowman;
