import * as _ from 'lodash';
import { Composition } from 'remotion';
import '../src/index.css';
import { compositionMapping } from '../src/services/TemplatesMappingService';
import { templates } from '../src/shared/services/TemplatesService';
import SampleComposition from './compositions/sample/SampleComposition';
import { sampleSchema } from './compositions/sample/SampleSchema';
import { SurgyComposition } from './compositions/surgy/SurgyComposition';

export const RemotionRoot = () => {
  const flattedTemplates = _.uniqBy(
    _.flatMap(templates, item => item.videos),
    'sku'
  );
  return (
    <>
      {flattedTemplates.map(video => {
        const platform: any = Object.keys(video.platforms)[0];
        return (
          <Composition
            fps={24}
            id={video.sku}
            component={compositionMapping[video.sku + '-' + platform]}
            durationInFrames={video.platforms[platform].compositionDuration}
            width={video.platforms.web.compositionWidth}
            height={video.platforms.web.compositionHeight}
            schema={video.schema}
            defaultProps={video.languages.english.props}
          />
        );
      })}
      <Composition
        id="sample-video"
        component={SampleComposition}
        durationInFrames={60}
        fps={24}
        width={1280}
        height={720}
        schema={sampleSchema as any}
        defaultProps={{ text: 'Sample' }}
      />
      <Composition id="surgy-trailer" component={SurgyComposition} durationInFrames={6 * 60} fps={30} width={1280} height={720} />
    </>
  );
};
