import { useMemo } from 'react';
import { useCurrentFrame, useVideoConfig } from 'remotion';
import { z } from 'zod';
import logo from '@/remotion/assets/images/logo.png';
import { Center } from '@/remotion/helpers/Center.tsx';
import { Image } from '@/remotion/helpers/Image.tsx';
import { Text } from '@/remotion/helpers/Text.tsx';
import { Wraper } from '@/remotion/helpers/Wraper';
import { logoAnimationSchema } from '@/shared/schemas/TechnologySchema.ts';
import { getTemplateVideo, templateSkus } from '@/shared/services/TemplatesService.ts';
import { fadeInEffect, heartBeatEffect, rotateEffect, springEffect } from '../../services/utils';

const LogoFadeInAnimation: React.FC<z.infer<typeof logoAnimationSchema>> = props => {
  const { color = '#000000', bgColor = '#ffffff', name, message, image, animation = 'Spring' } = props;
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const spring = springEffect(frame, fps, 0, 1, 30);
  const opacity = fadeInEffect(frame, 0, 30);
  const scale = heartBeatEffect(frame % 60, 60);
  const rotate = rotateEffect(frame, 0, 200, 360, 0);
  const template = useMemo(() => getTemplateVideo('technology', templateSkus.logoAnimation), []);

  const animationStyle = {
    FadeIn: { opacity },
    Rotation: { transform: `rotate(${rotate}deg)` },
    Spring: { transform: `scale(${spring})` },
    HeartBeat: { transform: `scale(${scale})` }
  };

  return (
    <Wraper color={props.color} template={template} data={props}>
      <Center className="w-full h-full bg-white" style={{ backgroundColor: bgColor }}>
        <Image source={image || logo} className="w-[130px] h-[130px] mb-2" from={5} to={30} style={animationStyle[animation]} />
        {!!name && (
          <Text className="text-[40px] mb-2" from={20} to={50} color={color}>
            {name}
          </Text>
        )}
        {!!message && (
          <Text className="text-[30px]" from={40} to={70} color={color}>
            {message}
          </Text>
        )}
      </Center>
    </Wraper>
  );
};

export default LogoFadeInAnimation;
