import { Img, Series, useCurrentFrame } from 'remotion';
import birthdayBoy from '@/remotion//assets/images/birthday-boy.jpg';
import { fadeInEffect, heartBeatEffect } from '@/remotion/services/utils';
import { Text } from './Text';

interface Props {
  className: string;
  color: string;
  message: string;
  image?: string;
  defaultImage?: string;
  language: string;
  labels: any;
}

const WishesPhotoContent = (props: Props) => {
  const { message, image, defaultImage = '', className, color } = props;
  const frame = useCurrentFrame();
  const totalFrames = 60;
  const opacity = fadeInEffect(frame, 0, 30);
  const scale = heartBeatEffect(frame % totalFrames, totalFrames);

  return (
    <Series>
      <Series.Sequence durationInFrames={290}>
        <div className={className}>
          <Img
            className="w-[150px] h-[150px] rounded-full mb-5 border-[5px]"
            style={{ transform: `scale(${scale})`, opacity, borderColor: color }}
            src={image || defaultImage || birthdayBoy}
          />
          <Text className="text-[32px] mb-5 text-center leading-relaxed line-clamp-5" color={color} from={20} to={50}>
            {message}
          </Text>
        </div>
      </Series.Sequence>
    </Series>
  );
};

export default WishesPhotoContent;
